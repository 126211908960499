/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@font-face {
    font-family: 'VodafoneRg';
    src: url('assets/fonts/VodafoneRg.eot'); /* IE 9 - 11 */
    src: url('assets/fonts/VodafoneRg.woff2') format('woff2'), url('assets/fonts/VodafoneRg.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'VodafoneRgCz';
    src: url('assets/fonts/VodafoneRgCz.eot'); /* IE 9 - 11 */
    src: url('assets/fonts/VodafoneRgCz.woff2') format('woff2'), url('assets/fonts/VodafoneRgCz.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'VodafoneRgBd';
    src: url('assets/fonts/vodafonergbd-webfont.eot'); /* IE 9 - 11 */
    src: url('assets/fonts/vodafonergbd-webfont.woff2') format('woff2'), url('assets/fonts/vodafonergbd-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'VodafoneRgBdCz';
    src: url('assets/fonts/vodafonergbdcz-webfont.eot'); /* IE 9 - 11 */
    src: url('assets/fonts/vodafonergbdcz-webfont.woff2') format('woff2'), url('assets/fonts/vodafonergbdcz-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'VodafoneLt';
    src: url('assets/fonts/VodafoneLt.eot'); /* IE 9 - 11 */
    src: url('assets/fonts/VodafoneLt.woff2') format('woff2'), url('assets/fonts/VodafoneLt.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/*
@font-face {
    font-family: 'VodafoneLtCz';
    src: url('assets/fonts/VodafoneLtCz.eot');
    src: url('assets/fonts/VodafoneLtCz.woff2') format('woff2'), url('assets/fonts/VodafoneLtCz.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
*/

::-webkit-scrollbar {
    width: 6px;
    height: 6px; 
}
    
::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
    border: 2px solid transparent;
    background-clip: padding-box; 
}

::-webkit-scrollbar-thumb:hover{
    border: 0;
}

body {
    font-family: "VodafoneRg", "VodafoneRgCz", Arial, sans-serif;
    font-size: 18px;
    background-color: #f2f2f2;
    padding-top: 70px;
}

.bold {
    font-family: 'VodafoneRgBd';
}

img, svg {
    vertical-align: top;
}

.clear {
    clear: both;
}

#main-body {
    min-height: calc(100vh - 157px);
}

#main-body {
    padding: 30px 0;
}

.container {
    position: relative;
    width: 90%;
    max-width: none;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right!important;
}


.main-block-white {
    box-shadow: 0 2px 4px #0003;
    background-color: #FFF;
    border-radius: 6px;
    padding: 20px;
}

.main-block-white + .main-block-white {
    margin-top: 20px;
}

::placeholder {
    color: #7e7e7e;
    opacity: 1;
}

::-ms-input-placeholder {
    color: #7e7e7e;
}

*:focus, .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: none!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}

p {
    margin-bottom: 10px;
}

select {
    -moz-appearance:none;
    -webkit-appearance:none
}
select::-ms-expand {
    display:none
}

.upload-field {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 200px;
}

input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}

.file-upload-button {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 20px;
    cursor: pointer;
    width: 100%;
    border: 0;
    line-height: 20px;
    border-radius: 6px;
    background-color: #0d0d0d;
    color: #FFF;
    text-align: center;
    height: 40px;
    margin: 0; 
}

.file-upload-button:hover {
    background-color: #262626;
}

select, .p-dropdown, .p-multiselect {
    height: 46px;
    border-radius: 6px;
    border: solid 1px #0d0d0d;
    background-color: transparent;
    color: #0d0d0d;
    font-size: 20px;
    line-height: 44px;
    padding-left: 16px;
    padding-right: 50px;
    background: url(assets/images/chevron-down.png) no-repeat right 15px center;
    cursor: pointer;
    font-weight: normal;
    font-family: "VodafoneRg", Arial, sans-serif!important;
    width: 100%;
}


.p-dropdown, .p-multiselect {
    border: solid 1px #bebebe!important;
    box-shadow: none!important;
    background-color: #FFF;
}

.p-dropdown-trigger-icon {
    display: none;
}

.p-dropdown .p-dropdown-label, .p-dropdown .p-dropdown-label.p-placeholder {
    padding-left: 0;
    padding-right: 0;
    color: #0d0d0d;
}

.p-dropdown-panel {
    box-shadow: 0 2px 4px #0003;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item, .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 0 1.25rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight, .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #0d0d0d;
    background-color: #f2f2f2;
}

.p-dropdown .p-dropdown-clear-icon {
    color: #000;
    border: 2px solid #0d0d0d;
    border-radius: 50%;
    padding: 2px;
}

.p-icon {
    width: 17px;
    height: 17px;
}

.p-multiselect {
    width: 100%;
    max-width: 100%;
}

.p-multiselect .p-icon-wrapper {
    margin-top: 0;
}

.p-checkbox .p-checkbox-box {
    border: 1px solid #7e7e7e;
    border-radius: 3px;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #7e7e7e;
    background-color: #00697c;
}

.p-multiselect-trigger-icon {
    display: none;
}

.p-multiselect .p-multiselect-label {
    padding: 0;
}

.main-dropdown {
    display: inline-block;
    position: relative;
    height: 46px;
    border-radius: 6px;
    border: solid 1px #0d0d0d;
    background-color: transparent;
    color: #0d0d0d;
    font-size: 20px;
    line-height: 44px;
    padding-left: 16px;
    padding-right: 50px;
    background: url(assets/images/chevron-down.png) no-repeat right 15px center;
    cursor: pointer;
    font-weight: normal;
    text-align: left;
}

.main-dropdown.open {
    color: #0d0d0d;
    background: url(assets/images/chevron-up.png) no-repeat right 15px center;
}

.main-dropdown ul {
    display: none;
    white-space: nowrap;
    position: absolute;
    left: -1px;
    top: calc(100% + 5px);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: #ffffff!important;
    padding: 5px 16px 16px 16px;
    min-width: calc(100% + 2px);
    z-index: 9;
    border-radius: 6px;
}

.main-dropdown li {
    display: block;
    line-height: 24px;
    font-size: 20px;
    font-size: 18px;
    color: #333;
}

.main-dropdown li label {
    padding-top: 2px;
}

.main-dropdown h3 {
    font-family: VodafoneRgBd;
    font-size: 20px;
    line-height: 21px;
    margin-bottom: 7px;
    margin-top: 7px;
}

.main-dropdown.table-cols {
    border: none;
    padding-left: 32px;
    background: url(assets/images/columns.svg) no-repeat left center;
    padding-right: 5px;
}

.main-dropdown.filters {
    border: none;
    padding-left: 32px;
    background: url(assets/images/filter.svg) no-repeat left center;
    padding-right: 5px;
}

.main-dropdown.table-cols ul {
    left: calc(-50% + 12px);
    top: 100%;
}

.main-dropdown.filters ul {
    left: -10px;
    top: 100%;
}

.main-dropdown.table-cols ul:before,
.main-dropdown.filters ul:before {
    display: block;
    content: "";
    position: absolute;
    width: 14px;
    height: 8px;
    left: calc(50% - 7px);
    top: -7px;
    background: url(assets/images/pointer.svg) no-repeat center center;
}

.main-dropdown.filters ul:before {
    left: 15px;
}

.success-messsage {
    position: fixed;
    z-index: 99999;
    left: 0;
    bottom: 0;
    right: 0px;
    font-size: 20px;
    line-height: 21px;
    background-color: #333333;
    color: #FFF;
    text-align: center;
    padding: 25px 0px 27px 0px;
    -webkit-animation: fadeInFromNone 0.1s ease-out;
    -moz-animation: fadeInFromNone 0.1s ease-out;
    -o-animation: fadeInFromNone 0.1s ease-out;
    animation: fadeInFromNone 0.1s ease-out;
    line-height: 30px;
}

.success-messsage .container {
    position: relative;
}

.success-messsage .container:before {
    display: inline-block;
    content: "";
    width: 35px;
    margin-right: 10px;
    margin-bottom: -10px;
    height: 35px;
    border-radius: 50%;
    background: #41871b url(assets/images/success-tick.svg) 6px 6px no-repeat;
}

.error-messsage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.7);
    z-index: 999999;
}

.error-messsage .container {
    position: fixed;
    z-index: 999999;
    left: 50%;
    top:  50%;
    font-size: 20px;
    line-height: 21px;
    background-color: #333333;
    color: #FFF;
    text-align: center;
    padding: 25px 60px 27px 30px;
    -webkit-animation: fadeInFromNone 0.1s ease-out;
    -moz-animation: fadeInFromNone 0.1s ease-out;
    -o-animation: fadeInFromNone 0.1s ease-out;
    animation: fadeInFromNone 0.1s ease-out;
    line-height: 30px;
    max-width: 90%;
    width: auto;
    transform: translate(-50%, -50%);
    text-align: center;
}

.error-messsage .container:before {
    display: inline-block;
    content: "";
    width: 35px;
    margin-right: 10px;
    margin-bottom: -10px;
    height: 35px;
    border-radius: 50%;
    background: #990000 url(assets/images/error-icon.svg) 5px 6px no-repeat;
}

.error-message-close {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.error-message-close img {
    width: 27px;
}

.warning-messsage {
    position: fixed;
    z-index: 99999;
    left: 0;
    bottom: 0;
    right: 0px;
    font-size: 20px;
    line-height: 21px;
    background-color: #333333;
    color: #FFF;
    text-align: center;
    padding: 25px 0px 27px 0px;
    -webkit-animation: fadeInFromNone 0.1s ease-out;
    -moz-animation: fadeInFromNone 0.1s ease-out;
    -o-animation: fadeInFromNone 0.1s ease-out;
    animation: fadeInFromNone 0.1s ease-out;
    line-height: 30px;
}

.warning-messsage .container {
    position: relative;
}

.warning-messsage .container:before {
    display: inline-block;
    content: "";
    width: 35px;
    margin-right: 10px;
    margin-bottom: -10px;
    height: 35px;
    border-radius: 50%;
    background: transparent url(assets/images/warning-white.svg) 2px 2px no-repeat;
    background-size: 30px;
}

.warning-message-close {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 50%;
    cursor: pointer;
    margin-top: -13px;
}

.warning-message-close img {
    width: 27px;
}

.info-messsage {
    position: fixed;
    z-index: 99999;
    left: 0;
    bottom: 0;
    right: 0px;
    font-size: 20px;
    line-height: 21px;
    background-color: #333333;
    color: #FFF;
    text-align: center;
    padding: 25px 15px 27px 15px;
    -webkit-animation: fadeInFromNone 0.1s ease-out;
    -moz-animation: fadeInFromNone 0.1s ease-out;
    -o-animation: fadeInFromNone 0.1s ease-out;
    animation: fadeInFromNone 0.1s ease-out;
    line-height: 30px;
}

.info-messsage .container {
    position: relative;
}

.info-messsage .container:before {
    display: inline-block;
    content: "";
    width: 35px;
    margin-right: 10px;
    margin-bottom: -10px;
    height: 35px;
    border-radius: 50%;
    background: transparent url(assets/images/info-white.svg) 2px 1px no-repeat;
    background-size: 32px;
}

.info-message-close {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 50%;
    cursor: pointer;
    margin-top: -13px;
}

.info-message-close img {
    width: 27px;
}

.csv-download {
    font-size: 20px;
    line-height: 46px;
    padding: 0 0 0 32px;
    background: url(assets/images/download.svg) no-repeat left center;
    background-color: transparent;
    border: 0;
    margin-left: 24px;
}

.download-icon-in-table {
    cursor: pointer;
    margin-right: 20px;
    background-color: transparent;
    border: 0;
}

.refresh-page {
    font-size: 20px;
    line-height: 46px;
    padding: 0 0 0 32px;
    background: url(assets/images/refresh.svg) no-repeat left center;
    background-color: transparent;
    border: 0;
    margin-left: 24px;
    float: right;
}

.download-and-col-filter {
    text-align: right;
    float: right;
}

input[type=checkbox] {
    display: none;
}

input[type="checkbox"] + label {
    cursor: pointer;
    margin-bottom: 0px;
    padding-left: 30px;
    position: relative;
    min-height: 20px;
}

.form-item input[type="checkbox"] + label {
    line-height: 25px;
}

input[type=checkbox][disabled] + label {
    opacity: 0.5;
}

input[type="checkbox"]:not(.hidden, .modal-chkbox) + label:before {
    content:"";
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #7e7e7e;
    background-color: #FFF;
    border-radius: 3px;
    position: absolute;
    left: 0px;
    top: 3px;
}

input[type="checkbox"]:checked+label::before {
    background: #00697c url(assets/images/checkbox.svg) center center no-repeat;
}

.button {
    height: 44px;
    padding: 9px 32px!important;
    border-radius: 6px;
    border: solid 2px #0d0d0d;
    line-height: 24px!important;
    cursor: pointer;
    background-color: transparent;
}

.button:hover {
    border: 2px solid #262626;
}

.button:disabled {
    background-color: #cccccc!important;
    border: 0;
}

.button.save {
    background-color: #e60000;
    color: #FFF;
    border: 0;
}

.button.save:hover {
    background-color: #BD0000;
}

a.button.save {
    text-decoration: none;
    vertical-align: top;
    display: inline-block;
}

.button-center {
    text-align: center;
}

.button img {
    margin-right: 5px;
}

.items-number {
    float: left;
    font-size: 18px;
    line-height: 46px;
}

.details-block-visible {
    width: 40%;
    float: left;
}

.details-block-right {
    width: 60%;
    padding-left: 20px;
    float: right;
}

.details-block {
    box-shadow: 0 2px 4px #0003;
    background-color: #FFF;
    border-radius: 6px;
    padding: 20px;
    min-height: 560px;
    position: relative;
}

h3.details-title {
    font-size: 20px;
    font-family: VodafoneRgBd;
    line-height: 32px;
    margin-bottom: 20px;
    padding-right: 35px;
}

.details-close {
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: transparent;
    border: 0;
}

.hidden, .modal-chkbox {
    display: none;
}

.form-item {
    margin-bottom: 12px;
    position: relative;
}

.form-item label {
    display: block;
    font-size: 14px;
    margin: 0;
    padding: 0 0 8px 0;
}

.form-item input, .form-item textarea, .form-item select, .p-inputtext, .p-autocomplete .p-autocomplete-multiple-container {
    border-radius: 6px;
    border: solid 1px #bebebe!important;
    background-color: #fff;
    font-size: 20px;
    line-height: 26px;
    padding: 9px 40px 9px 15px;
    width: 100%;
    min-width: 100%;
    font-family: VodafoneRg;
    outline: none!important;
}

.form-item input:focus, .form-item textarea:focus, .form-item select:focus, .p-inputtext:focus, .p-autocomplete .p-autocomplete-multiple-container:focus, .p-component.p-inputwrapper-focus {
    border: solid 1px #0096AD!important;
}

.p-inputtext, .p-inputtext:focus {
    border: 0!important;
    background-color: transparent;
}

.p-element ul, .p-element ol {
    padding-left: 0;
    margin-bottom: 0;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    border-radius: 5px;
}

.p-disabled, .p-component:disabled,
.form-item input:disabled, .form-item textarea:disabled, .form-item select:disabled, .form-control:disabled {
    opacity: 1;
    background-color: #F2F2F2;
    border: 1px solid #BEBEBE!important;
}

.p-component {
    font-family: "VodafoneRg", "VodafoneRgCz", Arial, sans-serif;
    color: #0d0d0d!important;
}

.input-icon-button,
.delete-input-icon-button {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    width: 20px;
    margin-left: 15px;
    color: #bd0000;
}

.form-item input.input-with-button,
.form-item textarea.input-with-button,
.form-item input.input-with-delete-button,
.form-item .input-with-delete-button,
.form-item input.input-with-unit {
    width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    margin-bottom: 10px;
    display: inline-block;
}

.form-item input.input-with-substring {
    width: calc(100% - 100px);
    min-width: calc(100% - 100px);
}

.add-new-field-button {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    color: #bd0000;
    font-size: 14px;
}

.form-item .p-autocomplete {
    width: 100%;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    background-color: #f2f2f2;
    color: #0d0d0d;
}

.button-under-table {
    background-color: #0d0d0d;
    color: #FFF;
    padding: 10px 30px;
    border-radius: 6px;
    margin-left: 18px;
    display: inline-block;
    margin-top: 21px;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
}

/* modal */

.disable-modal-label {
    display: none;
}

.a-tabs-hide {
    margin-left: 30px;
    text-decoration: none;
    cursor: pointer;
}

.tabbed figure, .tabbed-modal figure {
    display: block;
    margin-left: 0;
    border-top: 1px solid silver;
    clear: both;
    font-size: 18px;
}

.tabbed .item {
    line-height: 22px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.tabbed .item .label {
    width: 180px;
    font-family: VodafoneRgBd;
    display: inline-block;
    vertical-align: top;
}

.label-padding-left {
    padding-left: 15px;
}

.tabbed .item .value {
    width: calc(100% - 180px);
    display: inline-block;
}

.tabbed>input,
.tabbed figure>div {
    display: none;
}

.tabbed figure>div,
.tabbed-modal figure>div,
.tabbed-modal>div {
    padding: 16px 20px;
    background-color: #f2f2f2;
    margin-bottom: 20px;
}

.tabbed-modal>input,
.tabbed-modal figure>div {
    display: none;
}

#tab1:checked~figure .tab1,
#tab2:checked~figure .tab2,
#tab3:checked~figure .tab3,
#tab4:checked~figure .tab4,
#tab5:checked~figure .tab5,
#tab6:checked~figure .tab6,
#tab7:checked~figure .tab7,
#tab8:checked~figure .tab8,
#tab9:checked~figure .tab9,
#tab10:checked~figure .tab10,
#tab11:checked~figure .tab11,
#tab12:checked~figure .tab12,
#tab13:checked~figure .tab13,
#tab14:checked~figure .tab14,
#tab15:checked~figure .tab15,
#tab16:checked~figure .tab16,
#tab17:checked~figure .tab17,
#tab18:checked~figure .tab18,
#tab19:checked~figure .tab19,
#tab20:checked~figure .tab20 {
    display: block;
}

#tab1:checked~nav label[for="tab1"],
#tab2:checked~nav label[for="tab2"],
#tab3:checked~nav label[for="tab3"],
#tab4:checked~nav label[for="tab4"],
#tab5:checked~nav label[for="tab5"],
#tab6:checked~nav label[for="tab6"],
#tab7:checked~nav label[for="tab7"],
#tab8:checked~nav label[for="tab8"],
#tab9:checked~nav label[for="tab9"],
#tab10:checked~nav label[for="tab10"],
#tab11:checked~nav label[for="tab11"],
#tab12:checked~nav label[for="tab12"],
#tab13:checked~nav label[for="tab13"],
#tab14:checked~nav label[for="tab14"],
#tab15:checked~nav label[for="tab15"],
#tab16:checked~nav label[for="tab16"],
#tab17:checked~nav label[for="tab17"],
#tab18:checked~nav label[for="tab18"],
#tab19:checked~nav label[for="tab19"],
#tab20:checked~nav label[for="tab20"] {
    border-bottom: 4px solid #e60000;
}

nav label {
    float: left;
    padding: 15px;
    border-bottom: 4px solid #FFF;
    cursor: pointer;
}


nav label:hover {
    border-bottom: 4px solid #e60000;
}

nav label:active {
    border-bottom: 4px solid #e60000;
}

.modal-chkbox:checked~.modal,
.modal-chkbox:checked~.modal-background {
    display: block;
}

.modal-background {
    width: 100%;
    height: 100%;
    background-color: rgba(13, 13, 13, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 9998;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 90%;
    max-width: 640px;
    height: auto;
    max-height: 90%;
    background-color: #fff;
    box-shadow: 0 2px 4px #0003;
    padding: 16px 16px 30px 16px;
    border-radius: 8px;
    z-index: 9999;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    line-height: 30px;
    padding: 0 5px;
    margin-right: 5px;
    font-size: 17px;
    background-color: #f2f2f2;
    color: #0d0d0d;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0;
}

.p-multiselect-chip {
    padding-left: 10px;
}

.p-multiselect-chip .p-multiselect-label {
    white-space: inherit;
}

.p-multiselect-chip .p-multiselect-label-container {
    overflow: visible;
    max-width: 100%;
    flex: none;
}

.p-multiselect-chip.p-multiselect {
    height: auto;
}

.modal-alternativ {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 2px 4px #0003;
    padding: 16px;
    border-radius: 8px;
}

h3.modal-title {
    text-align: center;
    font-family: VodafoneLt;
    font-size: 28px;
    margin-bottom: 30px;
    margin-top: 18px;
}

.close-modal {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
}

/* end modal */

/* filter */

.filter-item {
    display: inline-block;
    height: 30px;
    padding: 1px 1px 1px 8px;
    border-radius: 7px;
    background-color: #00697c;
    margin-right: 10px;
}

.filter-label {
    display: table-cell;
    padding-right: 8px;
    font-size: 14px;
    color: #FFF;
    font-family: VodafoneRgBd;
    height: 28px;
    vertical-align: top;
    line-height: 28px;
}

.filter-value {
    display: table-cell;
    max-width: 200px;
}

.filter-value input {
    min-width: 75px;
    border-radius: 7px;
    background-color: #FFF;
    height: 28px;
    line-height: 26px;
    border: 1px solid #00697c;
    padding: 0px 10px;
    max-width: 200px;
    font-size: 18px;
}

.filter-value select {
    border-radius: 7px;
    background-color: #FFF;
    height: 28px;
    line-height: 26px;
    border: 1px solid #00697c;
    padding: 0px 50px 0 10px;
    font-size: 18px;
}

.filter-close {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
}

.filter-close img {
    vertical-align: top;
    margin-top: 6px;
    margin-right: 6px;
}

/* table */

.table-row-button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0px 0px 0px 20px;
}

.table-row-button:first-child {
    margin-left: 0px;
}

.table-row-button img {
    vertical-align: middle;
}

.main-table-options, .main-table-filters {
    padding: 0 20px 10px 20px;
}

.main-table-filters.details-block-visible {
    width: 100%;
}

.row-link {
    cursor: pointer;
}

.p-datatable-wrapper {
    min-height: 100px;
}

.p-icon-wrapper {
    margin-top: -10px;
}

.p-datatable .p-datatable-header {
    background-color: transparent!important;
    border: 0!important;
    font-weight: normal;
}

.p-overlay {
    max-width: 100%;
}

.main-table-options::after {
    display: block;
    content: "";
    clear: both;
}

.status-col .online:before {
    background: transparent url(assets/images/online.svg) center center no-repeat;
    background-size: contain;
}

.status-col .offline:before {
    background: transparent url(assets/images/offline.svg) center center no-repeat;
    background-size: contain;
}

.status-col .disabled:before {
    background: transparent url(assets/images/disabled.svg) center center no-repeat;
    background-size: contain;
}

.status-col .terminate:before {
    background: transparent url(assets/images/terminate.svg) center center no-repeat;
    background-size: contain;
}

.status-col .error:before {
    background: transparent url(assets/images/error.svg) center center no-repeat;
    background-size: contain;
}

.status-col span:before {
    display: inline-block;
    content: "";
    height: 21px;
    width: 21px;
    vertical-align: middle;
    margin-right: 10px;
}

td .working {
    vertical-align: middle;
}

.td-with-click-event {
    cursor: pointer;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
    border-bottom: 1px solid #4A4D4E;
    background-color: transparent;
    padding: 10px 15px;
    color: #0d0d0d;
}

.p-datatable .p-datatable-thead > tr > th:last-child,
.p-datatable .p-datatable-tbody > tr > td:last-child {
    padding-right: 30px;
}

.p-datatable .p-datatable-tbody > tr > td.buttons {
    text-align: right;
    min-width: 115px;
}

.p-datatable .p-datatable-thead > tr > th {
    padding-top: 0px;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover,
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight:hover {
    background-color: transparent;
    color: #0d0d0d;
}

.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: #0d0d0d;
    margin-top: 5px;
}

.p-sortable-column .p-icon {
    height: 12px;
    width: auto;
}

.p-datatable .p-datatable-thead > tr > th.th-check ,
.p-datatable .p-datatable-tbody > tr > td.td-check {
    padding-left: 3px;
    padding-right: 0;
    width: 30px;
}

.p-component-overlay {
    background-color: transparent;
}

.p-component-overlay {
    height: calc(100% + 70px);
}

.p-datatable-wrapper {
    box-shadow: 0 2px 4px #0003;
    background-color: #FFF;
    border-radius: 6px;
    border-left: 20px solid white;
    border-right: 20px solid white;
    border-top: 15px solid white;
    border-bottom: 15px solid white;
    padding-bottom: 15px;
    position: relative;
}

.tabbed .p-datatable-wrapper {
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    border: 0px;
}

.table-outside-div {
    position: relative;
}

.table-outside-div:after {
    transition: all 0.5s ease;
    position: absolute;
    display: block;
    content: "";
    right: 19px;
    top: 0px;
    bottom: 0px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    width: 0px;
    opacity: 0;
}

.table-outside-div.over:after {
    opacity: 1;
    width: 35px;
}

.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-paginator-pages .p-paginator-page:focus {
    border: 2px solid #0d0d0d;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    height: 40px;
    min-width: 40px;
    color: #0d0d0d;
    margin-right: 5px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-paginator .p-paginator-pages .p-paginator-page:hover {
    background-color: #0d0d0d!important;
    border: 2px solid #0d0d0d!important;
    color: #FFF!important;
    box-shadow: none;
}

.p-paginator-last, .p-paginator-first {
    display: none!important;
}

.p-paginator {
    justify-content: right!important;
    background-color: transparent;
    padding: 20px 0;
    align-items: unset;
}

.p-paginator-element.p-disabled {
    border: none!important;
}

.details-block-visible .item-per-page select {
    width: 80px;
}

.details-block-visible .p-paginator .p-paginator-pages .p-paginator-page,
.details-block-visible .p-paginator .p-paginator-pages .p-paginator-page:focus,
.details-block-visible .p-paginator .p-paginator-prev,
.details-block-visible .p-paginator .p-paginator-next {
    min-width: 25px;
    padding: 0 4px;
}

.p-datatable-wrapper + .p-element {
    display: inline-block;
    float: right;
}

.table-block:after {
    display: block;
    content: "";
    clear: both;
}

.p-paginator .p-paginator-prev, .p-paginator .p-paginator-prev:focus,
.p-paginator .p-paginator-next, .p-paginator .p-paginator-next:focus {
    background-color: transparent!important;
    border-radius: 0;
    box-shadow: none;
}

.item-per-page {
    display: inline-block;
    float: right;
    padding: 21px 0;
}

.item-per-page select {
    border: 1px solid #0d0d0d;
    border-radius: 7px;
    height: 40px;
    line-height: 40px;
    width: 100px;
    padding: 0 10px;
    margin-left: 15px;
    font-size: 1rem;
    background-color: transparent;
}

.block-with-background {
    background-color: #f2f2f2;
    padding: 20px;
}

.mass-update {
    margin-top: 21px;
    max-width: 100%;
    width: 690px;
    padding-left: 20px;
}

.mass-update-counter {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    vertical-align: middle;
    padding: 0 15px;
    margin-top: -4px;
    min-width: 125px;
}

.mass-update select {
    height: 40px;
    line-height: 38px;
    max-width: 220px;
}

.mass-update button {
    height: 40px;
    line-height: 20px!important;
    background-color: #0d0d0d;
    color: #FFF;
    padding-left: 5px!important;
    padding-right: 5px!important;
    text-align: center;
    width: 130px;
}

.mass-update button:hover, .button-under-table:hover {
    background-color: #262626;
}

.selectAllCheckbox {
    padding-top: 10px;
    min-height: 40px;
}

.delete-x {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 10px;
    bottom: 12px;
    height: 18px;
}

.delete-x img {
    width: 16px;
    height: 16px;
}

/* chart */

.chart-block {
    margin-top: 15px;
    margin-bottom: 30px;
}

.chart-item {
    margin-bottom: 15px;
}

.chart-item::after, .chart-item-labels::after {
    display: block;
    content: "";
    clear: both;
}

.chart-item-icon {
    width: 50px;
    height: 50px;
    float: left;
}

.chart-item-icon:before {
    display: block;
    content: "";
    width: 36px;
    height: 36px;
    background-color: #bebebe;
    border-radius: 6px;
    background-image: url(assets/images/stat.svg);
    background-position: center center;
    background-repeat: no-repeat;
    margin-top: 3px;
}

.chart-item-icon.International:before {
    background-image: url(assets/images/stat-international.svg);
}

.chart-item-labels-graph {
    width: calc(100% - 50px);
    float: left;
}

.status .chart-item-labels-graph {
    width: 100%;
}

.status-button {
    cursor: pointer;
    float: right;
    line-height: 46px;
}

.status-button img {
    vertical-align: middle;
    margin-right: 5px;
}

.chart-item-graph {
    height: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #bebebe;
    border-radius: 3px;
    overflow: hidden;
}

.chart-item-usage {
    background-color: #00697c;
    width: 50%;
    float: left;
    height: 6px;
    border-right: 2px solid #FFF;
    box-sizing: content-box;
}

.status .chart-item-usage-label {
    width: 100%;
    text-align: center;
}

.status-load-icon {
    animation: p-icon-spin 2s infinite linear;
    margin-top: -5px;
    width: 18px;
}

.status-finished-icon {
    width: 30px;
    margin-top: -3px;
}

.status-warining-icon {
    width: 26px;
    margin-top: -4px;
}

.status-error {
    color: #eb6100;
}

.error-items {
    max-height: 300px;
    overflow: auto;
    font-size: 16px;
    line-height: 28px;
}

.error-field {
    color: #bd0000;
    font-size: 14px;
}

.newPassword  + .error-field, .newPassword  + .error-field + .error-field {
    margin-left: 200px!important;
}

.chart-item-label {
    width: 50%;
    float: left;
}

.chart-item-usage-label {
    width: 50%;
    float: left;
    text-align: right;
}

.chart-item-legend, .chart-item-legend-other {
    font-size: 14px;
    display: inline-block;
}

.chart-item-legend-other {
    margin-left: 20px;
}

.chart-item-legend::before {
    display: inline-block;
    content: "";
    border-radius: 4px;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    background-color: #00697c;
}

.chart-item-legend-other::before {
    display: inline-block;
    content: "";
    border-radius: 4px;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    background-color: #bebebe;
}

/* end chart */

.info {
    width: 20px;
    height: 20px;
    border: 0;
    background: url(assets/images/info.svg) no-repeat left center;
      background-size: auto;
    background-size: cover;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
    z-index: 99;
    margin-bottom: -5px;
}

.info-text {
    display: none;
    width: 280px;
    border-radius: 5px;
    box-shadow: 0 1px 5px #0003;
    background-color: #fff;
    font-size: 14px;
    line-height: 1.2;
    padding: 15px;
    position: absolute;
    bottom: calc(100% + 12px);
    right: -140px;
    text-align: left !important;
}

.info-text::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background-color: #fff;
    box-shadow: 2px 2px 2px #0003;
    position: absolute;
    right: 145px;
    margin-top: -5px;
    top: 100%;
}

input.ng-touched.ng-invalid, .field-invalid {
    border: 1px solid #e60000;
}

.required_error_message {
    color: #e60000;
    font-size: 14px;
}

.group-2fa {
    margin-left: 200px;
}

.account .item, .account .edit-item {
    margin-bottom: 10px;
}

.account .item:last-child, .account .edit-item:last-child {
    margin-bottom: 0;
}

.account .item label {
    width: 200px;
}

.account .edit-item label {
    margin-left: 200px;
}

.account .block-with-background {
    position: relative;
}

.account .edit-button {
    position: absolute;
    right: 15px;
    top: 15px;
    border: 0;
    background-color: transparent;
}

.account .button {
    margin: 10px 15px 0px 15px;
}

.account .required_error_message {
    margin-left: 200px;
    font-size: 14px;
}

.account .change-password-block, .div-2fa {
    margin-bottom: 20px;
}

.account .item label.full-width {
    width: 100%;
}

.account .form-item label {
    display: inline-block;
}

.account .form-item input {
    width: calc(100% - 200px);
    min-width: 100px;
}

.account .item p {
    font-size: 14px;
}

.account .block-with-background + h3 {
    margin-top: 30px;
}

.account .qr-scan-link {
    background-color: transparent;
    border: 0;
    color: #e60000;
    font-size: 14px;
    padding: 0;
}

.account .header {
    font-family: "vodafoneRgBd";
}

.account .devicename {
    width: 200px;
    display: inline-block;
}

.account .from {
    width: calc(100% - 240px);
    display: inline-block;
}

.account .action {
    width: 40px;
    text-align: right;
    display: inline-block;
}

.account .deletedevice {
    background-color: transparent;
    padding: 0;
    border: 0;
}

.deletedevice img {
    vertical-align: middle;
}

.account .devices-list {
    margin-bottom: 20px;
    line-height: 35px;
}

.account .no-devices-text {
    margin-bottom: 20px;
}

.add-plus {
    font-size: 30px;
    vertical-align: middle;
    height: 20px;
    line-height: 17px;
    display: inline-block;
    margin-right: 10px;
}

.custom-modal-content, .modal2fa-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 640px;
    height: auto;
    max-height: 90%;
    background-color: #fff;
    box-shadow: 0 2px 4px #0003;
    padding: 16px;
    border-radius: 8px;
    z-index: 9999;
    overflow-x: hidden;
    overflow-y: auto;
}

.modal2fa-content {
    font-size: 14px;
}

.custom-modal:after, .modal2fa:after {
    width: 100%;
    height: 100%;
    background-color: rgba(13, 13, 13, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    content: "";
    z-index: 9998;
}

.modal2fa .block-with-background {
    margin-bottom: 20px;
}

.qr-code-alternative {
    background-color: #FFF;
    padding: 15px;
    margin-bottom: 10px;
    margin-top: 15px;
}

.qr-code-alternative p:last-child {
    margin-bottom: 0;
}

.qrcode {
    margin-bottom: 5px;
}

.secreet-qr-code {
    font-size: 18px;
    letter-spacing: 1px;
}

.col-view-input {
    width: 180px;
    border-radius: 6px;
    border: solid 1px #bebebe;
    background-color: #fff;
    font-size: 20px;
    line-height: 20px;
    padding: 6px 15px;
    font-family: VodafoneRg;
}

.col-view-save {
    width: 180px;
    margin-top: 10px;
    height: 36px;
    padding: 4px 32px !important;
}

.delete-modal .button {
    margin: 10px 15px 0px 15px;
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: #0d0d0d;
}

form .groups .p-overlay, form .simcardAcl .p-overlay, .traffic-profile-ban .p-overlay {
    top: auto!important;
    bottom: calc(100% + 1px)!important;
}

.loading-backend-layer {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
    top: 40px;
    background: rgba(255,255,255,1);
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
}

@media screen and (max-width: 1390px) {
    .mass-update {
        clear: both;
    }
}

@media screen and (max-width: 1200px) {
    .details-block-visible .item-per-page {
        float: left;
        text-align: center;
        width: 100%;
        padding: 0;
    }

    .details-block-visible .p-datatable-wrapper + .p-element {
        float: left;
        width: 100%;
    }

    .details-block-visible .p-paginator {
        display: block!important;
        text-align: center;
    }

    nav label {
        padding: 15px 10px;
    }

    .details-block-visible .download-and-col-filter, .details-block-visible .items-number {
        text-align: left;
        float: none;
        width: 100%;
    }

    .details-block-visible .main-dropdown.table-cols ul {
        left: -10px;
    }

    .details-block-visible .main-dropdown.table-cols ul::before {
        left: 15px;
    }
}

@media screen and (max-width: 900px) {
    .details-block-visible, .details-block-visible.main-table-filters {
        display: none;
    }

    .details-block-right {
        padding-left: 0;
        width: 100%;
    }
}

@media screen and (max-width: 680px) {
    .mass-update {
        padding-left: 0;
        text-align: center;
        padding-top: 20px;
        width: 100%;
    }

    .item-per-page {
        float: left;
        text-align: center;
        width: 100%;
        padding: 0;
    }

    .p-datatable-wrapper + .p-element {
        float: left;
        width: 100%;
    }

    .p-paginator {
        display: block!important;
        text-align: center;
    }

    .p-paginator .p-paginator-pages .p-paginator-page,
    .p-paginator .p-paginator-pages .p-paginator-page:focus,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next {
        min-width: 25px;
        padding: 0 4px;
    }

    .button-under-table {
        margin-left: 0;
        width: 100%;
    }

    .error-info-text {
        min-width: 0;
    }
}

@media screen and (max-width: 600px) {
    .download-and-col-filter, .items-number {
        text-align: left;
        float: none;
        width: 100%;
    }

    .main-dropdown.table-cols ul {
        left: -10px;
    }

    .main-dropdown.table-cols ul::before {
        left: 15px;
    }
}

@media screen and (max-width: 750px) {
    .mass-update select, .mass-update button {
        width: 100%;
        max-width: 100%;
    }

    .csv-update, .button-report {
        width: 100%;
        max-width: 100%;
        margin-left: 0!important;
        margin-top: 15px;
        display: block!important;
    }

    .mass-update select, .mass-update-counter {
        margin-bottom: 10px;
    }

}
